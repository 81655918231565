import './topbar.scss'
import chevron from "@amo/core/assets/images/icons/chevron-left-white.svg";
import _ from "lodash";

export const TopBar = props => {
    const {step, logo, isDirect, isMB, apporteur, formRappelAffiche, setFormRappelAffiche, previousPage, children, analyticsSetEvent = () => {}} = props
    const isLsaOrRvaOrAssurpeople = _.includes(['479846', '111619', '479835'], apporteur?.codeBelair)
    let tel;

    switch (apporteur?.codeBelair){
        case '479846':
            tel = '02 55 45 34 97'
            break;
        case '111619':
            tel = '02 21 64 38 51'
            break;
        case '479835':
            tel = '02 19 01 15 34'
            break;
        default:
            tel = '02 55 45 34 97'
    }

    return (
        <div className={'pb-3 top-bar'}>
            <div>
                <div className={'row m-0 p-0'}>
                    <div className={'col-xl-2 d-none d-lg-block text-center pt-2'}>
                        { step > 0 && <span className={`btn btn-return btn-smaller  me-3`} onClick={() => previousPage()}>Question précédente</span> }
                    </div>

                    <div className="col-3 d-lg-none d-xl-none pt-3">
                        { step > 0 && <span className={'btn btn-return-small me-3'} onClick={() => previousPage()}>
                            <img src={chevron} alt="<" width={12} />
                        </span> }
                    </div>

                    <div className={'col-6 col-xl-1 text-center d-flex flex-wrap align-content-center pt-3'} >
                        <img src={logo} alt={"Logo"} width={apporteur === '545403' ? 180 : 120}/>
                    </div>

                    { setFormRappelAffiche && <div className="d-block d-lg-none d-xl-none col-3 text-right pt-3">
                        {(isDirect && !isMB) ||  isLsaOrRvaOrAssurpeople && <span className={'btn btn-outline-primary btn-phone-small'} onClick={() => setFormRappelAffiche(!formRappelAffiche)}>&nbsp;</span> }
                    </div> }

                    {children}

                    {
                        (isDirect && !isMB) ||  isLsaOrRvaOrAssurpeople && <div className="mt-3 d-none d-lg-block col-xl-3 text-center" style={{right: 0}}>
                            <a href={`tel:${tel.replace(/\s/g, '')}`} className={'btn btn-outline-primary btn-phone'}>
                                <span className={'icon'} style={{top: '20px'}}></span>
                                <small>Un conseiller vous aide</small>
                                <span>{tel}</span>
                            </a>
                            <span
                                className={'d-block text-secondary f-13 mt-1 fw-bolder cursor-pointer'}
                                style={{lineHeight: '20px'}}
                                onClick={() => setFormRappelAffiche(!formRappelAffiche)}
                            ><small>ou</small> <b className={'underline-link'}>rappelez-moi</b></span>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
};
