import {dataNormalized} from '../../../actions/core/normalize/normalize.actions'

export const normalizeMiddleware = ({dispatch}) => (next) => (action) => {

    if (action.type.includes('Set') && action.meta && action.meta.normalizeKey) {

        dispatch(dataNormalized({entity: action.meta.entity}))

        const newAction = action

        newAction.payload.data = action.payload.data.reduce((acc, item) => {
            acc[item[action.meta.normalizeKey]] = item
            return acc
        }, {})

        newAction.meta.normalizeKey = null

        next(newAction)

    } else {
        next(action)
    }
}
